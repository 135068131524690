.container-landing {
    width: 100%;
    max-width: 425px;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);  
}

/* Status Bar */
.status-bar {
    width: 100%;
    height: 44px;
    background-color: #ffffff;
    color: #050505;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
    z-index: 2;
}

.status-bar .left {
    display: flex;
    align-items: center;
}

.status-bar .right {
    display: flex;
    align-items: center;
    gap: 5px;
}

.status-bar .icon {
    height: 14px;
    width: 63px;
}

/* Video banner section */
.video-banner {
    /* position: relative; */
    width: 100%;
    height: calc(100vh - 51px);
    /* height: calc(100vh - 80px);  */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

/* Overlays */
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 1;
}

.top-overlay {
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 100px;
}

.bottom-overlay {
    bottom: 0;
    height: 150px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

/* Content inside the video banner */
.video-content {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
}

.header-content h1 {
    font-size: 24px;
    margin-left: 10px;
}

.play-btn {
    align-self: center;
    cursor: pointer;
}

.play-btn img {
    width: 84px;
    height: 84px;
    /* background-color: rgba(255, 255, 255, 0.7); */
    border-radius: 50%;
    padding: 15px;
    margin: 222px 0;
}

.bottom-content {
    text-align: center;
    margin-bottom: 10px;
}

.bottom-content p {
    font-size: 16px;
    margin-bottom: 10px;
}

.instagram-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D235C3;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    font-size: 18px;
    z-index: 2;
}

.instagram-btn img {
    width: 20px;
    margin-left: 10px;
}

/* Footer */
footer {
    height: 50px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.footer-powered {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.footer-powered img {
    width: 100px;
    margin-left: 10px;
}

/* Modal styles and responsiveness as per provided CSS */

/* Responsiveness */
@media only screen and (max-width: 768px) {
    .header-content h1 {
        font-size: 20px;
    }
    .bottom-content p {
        font-size: 14px;
    }
    .instagram-btn {
        font-size: 16px;
    }
    .play-btn img {
        width: 50px;
        height: 50px;
    }
    .footer-powered img {
        width: 80px;
    }
}

/* Modal Container */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    /* z-index: 1000; */
    overflow: auto; /* Enable scrolling if content overflows */
}

/* Modal Content */
.modal-content {
    background: #ffffff;
    border-radius: 8px;
    /* width: 90%; 
    max-width: 400px;  */
    /* padding: 20px; */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    position: relative; /* For the close button */
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    border: none;
    background: none;
}

/* Success Message */
.success-message {
    color: green;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

/* Form Elements */
.modal-content form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.modal-content .form-group label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.modal-content .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.modal-content .submit-btn {
    background-color: #007BFF;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.modal-content .submit-btn:hover {
    background-color: #0056b3;
}

/* Responsive Adjustments */
@media only screen and (max-width: 480px) {
    .modal-content {
        width: 95%;
        max-width: 320px;
        padding: 15px;
    }
    .close-btn {
        font-size: 20px;
    }
}


.small-image-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0; /* Add margin for spacing */
  }
  
 
  
  .contentCenter {
    margin: 20px auto;
    max-width: 400px;
    text-align: center,
}
.detailsBox {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    text-align: left;
}

.detailsHeading {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}
.detailsRow {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 5px 0;
    color: #333;
}
.thankYouMessage {
    font-size: 14px;
    color: #555;
    margin: 10px 0;
}
.contactInfo {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
}

.left-section{
    align-self: start;
}