/* General Styles */
.forgot-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* background-color: #f9f9f9; */
}

.forgot-box {
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.forgot-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forgot-logo {
  width: 80px;
  margin: 0 auto 20px;
}

.forgot_text-area {
  border-radius: 4px;
  font-size: 1rem;
  padding: 10px;
}

.btn_forgot {
  font-weight: bold;
  background-color: #d440c6;
  color: #ffffff;
  border: none;
  width: 100%;
  padding: 10px;
}

.btn_forgot:hover {
  background-color: #b63ca5;
}

.forget-h6 {
  /* text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ccc;
  display: inline-block; */

  margin-bottom: 1px;
  border-bottom: 1px dashed #ccc;
}

/* Media Queries */

/* Large Devices (Desktops) */
@media (max-width: 1200px) {
  .forgot-box {
    padding: 25px;
  }
}

/* Tablets */
@media (max-width: 992px) {
  .forgot-box {
    padding: 20px;
  }

  .forgot-logo {
    width: 70px;
  }

  .forgot_text-area {
    font-size: 0.9rem;
    padding: 8px;
  }

  .btn_forgot {
    font-size: 0.9rem;
  }

  .forget-h6 {
    font-size: 1.3rem;
  }
}

/* Small Tablets and Large Phones */
@media (max-width: 768px) {
  .forgot-container {
    padding: 10px;
  }

  .forgot-box {
    padding: 15px;
  }

  .forgot-logo {
    width: 60px;
  }

  .btn_forgot {
    font-size: 0.85rem;
  }

  .forgot_text-area {
    font-size: 0.85rem;
  }
}

/* Small Phones */
@media (max-width: 576px) {
  .forgot-box {
    padding: 10px;
  }

  .forgot-logo {
    width: 50px;
  }

  .btn_forgot {
    font-size: 0.8rem;
    padding: 8px;
  }

  .forgot_text-area {
    font-size: 0.8rem;
    padding: 8px;
  }

  .forget-h6 {
    font-size: 1rem;
  }
}

/* Extra Small Devices */
@media (max-width: 400px) {
  .forgot-logo {
    width: 40px;
  }

  .btn_forgot {
    font-size: 0.7rem;
    padding: 6px;
  }

  .forgot_text-area {
    font-size: 0.75rem;
    padding: 6px;
  }

  .forget-h6 {
    font-size: 0.9rem;
  }
}
