/* General Styles */
.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.login-box {
  display: flex;
  background-color: #deffcc;
  max-width: 1138px;
  width: 100%;
  height: 462px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.login-info {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 0 58px;
}

.login-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.login_subHead {
  margin: 1rem 0;
  color: #6d6f71;
}

.sign-up-btn {
  font-weight: 600;
  border-radius: 29px;
  width: 191px;
  font-size: 17px;
  color: black;
  border: 1px solid black;
  background-color: white;
}

.login-form {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  width: 441px;
  height: 518px;
  margin: -61px 46px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1) !important;
  padding-top: 70px;
}

.form-class {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-logo {
  width: 100px;
  margin: 1rem auto;
  display: block;
}

.login_textbox_layout {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 0.5px solid #aca7a7;
  border-radius: 10px;
  margin: 1rem 0;
}

.text-area {
  width: 100%;
  border: none;
  outline: none;
}


.btn_login {
  font-weight: 600;
  border-radius: 29px;
  background-color: #e83e8c;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
}

.forgot-password {
  color: #212529;
  text-decoration: none;
  font-size: 0.9rem;
}

.error-message {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.5rem;
}

/* Media Queries */
@media (max-width: 1024px) {
  .login-box {
    max-width: 80%;
    height: auto;
    flex-direction: column;
    margin: 0 auto;
  }

  .login-info {
    padding: 1rem;
    text-align: center;
    margin: 0;
  }

  .login-form {
    width: 90%;
    margin: 1rem auto;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 768px) {
  .login-box {
    max-width: 100%;
  }

  .login-info {
    padding: 1rem;
    margin: 0;
    order: 2;
  }

  .login-form {
    margin: 1rem auto;
    width: 100%;
    box-shadow: none;
  }

  .sign-up-btn {
    width: 100%;
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .login-container {
    padding: 0.5rem;
  }

  .login-box {
    flex-direction: column;
    height: auto;
  }

  .login-info {
    padding: 1rem;
    text-align: center;
  }

  .login-form {
    width: 100%;
    margin: 1rem auto;
    padding-top: 20px;
    box-shadow: none;
  }

  .form-class {
    width: 92% !important;
}

  .login-title {
    font-size: 1.5rem;
  }

  .login_subHead {
    font-size: 0.9rem;
  }

  .sign-up-btn {
    font-size: 14px;
  }

  .btn_login {
    font-size: 14px;
  }

  .forgot-password {
    font-size: 0.8rem;
  }
}

.text-area {
  width: 100%;
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
}
