.signup-container {
    height: 100vh;
}

.signup-box {
    width: 94%;
    background-color: #deffcc;
    height: 580px;
}

.signup-info {
    /* background-color: #deffcc; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: left;
    margin: 40px -5px;
    width: auto;
}

.signup-title {
    font-size: 31px;
    font-weight: 600;
    width: 355px;
}

.signup_subHead {
    font-size: 14px;
    color: #777;
    width: 386px;
}

.signup-form {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 40%;
    height: auto;
    margin: -67px 76px;
}

.signup-logo {
    width: 120px;
}

.text-area-signup {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.password-toggle-icon-signup {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #777;
}

.btn_signup {
    background-color: #d435c5;
    color: #fff;
    border: none;
    height: 38px;
}

.btn_signup:hover {
    background-color: #b72d6c;
}

.error-message {
    font-size: 12px;
    color: red;
}

.btn-log {
    background-color: #ffffff;
    border: none;
    width: 156px;
    border-radius: 31px;
    border: 1px solid;
    height: 42px;
}

.signup-label {
    font-weight: 500;
}

.form-class-signup {
    width: 100%;
    margin: 0 auto;
}

.signup-input {
    border-radius: 12px;
}

/* Media Queries for Responsive Design */

/* Mobile devices */
@media only screen and (max-width: 576px) {
    .signup-box {
        width: 100%;
        height: auto;
    }

    .signup-info {
        margin: 0;
        padding: 20px;
    }

    .signup-form {
        width: 100% !important;
        margin: 0;
        padding: 20px;
    }

    .signup-title {
        font-size: 24px;
        width: auto;
    }

    .signup_subHead {
        font-size: 12px;
        width: auto;
    }

    .signup-logo {
        width: 100px;
    }

    .signup-input {
        font-size: 12px;
        padding: 8px;
    }

    .btn_signup {
        font-size: 14px;
        height: 35px;
    }

    .btn-log {
        width: 100%;
        margin-top: 15px;
    }
}

/* Tablets (Landscape and Portrait) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
    .signup-box {
        width: 100%;
        /* height: 100%; */
    }
    .signup-info{
        margin: 70px 0;
        background: none;
    }
    .signup-form {
        width: 100% !important;
        margin: -67px auto;
    }

    .signup-title {
        font-size: 28px;
        width: auto;
    }

    .signup_subHead {
        font-size: 14px;
        width: 300px;
    }

    .signup-input {
        font-size: 13px;
        padding: 10px;
    }

    .btn_signup {
        font-size: 15px;
    }
}
@media (min-width: 765px) and (max-width: 1000px){
    .signup-form{
        width: 100% !important;
    }
}
@media (max-width: 1200px) {
    /* .signup-form{
        width: 100%;
        margin: 0;
    } */
    .signup-form {
        width: 46%;
        margin: -69px 0;
    }
}

