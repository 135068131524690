

.mobile-screen {
    width: 100%;
    height: 100%;
    /* overflow: auto;
    padding: 20px; */
    /* Padding for content */
}

.mobile-status-bar {
    height: 24px;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 12px;
    font-family: Arial, sans-serif;
}

.status-left,
.status-center,
.status-right {
    display: flex;
    align-items: center;
}

.wifi-icon,
.sim-icon,
.battery-icon {
    margin-right: 5px;
}

.template-qr-container {
    position: relative;
    width: 100%;
    height: 96%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-content {
    position: relative;
    background: rgba(255, 255, 255, 0.8);
    /* White semi-transparent overlay */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    z-index: 2;
}

.template-content h2,
.template-content p,
.template-content h3 {
    margin: 10px 0;
}

.template-content .btn-download {
    margin-top: 15px;
}

.template-qr-container::before {
    content: '';
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.4); */
    /* Optional dark overlay for better text visibility */
    z-index: 1;
}

.template-content p,
.template-content h3 {
    margin: 10px 0;
    font-size: 14px;
    color: white;
}

.header-content {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    /* text-align: center; */
    z-index: 10;
    font-family: Arial, sans-serif;
    /* background-color: rgba(0, 0, 0, 0.5); */
    padding: 5px 10px;
    border-radius: 5px;
}

.header-content h2 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.header-content p {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
}
.footer-content {
    /* text-align: center; */
    font-family: Arial, sans-serif;
    /* background-color: rgba(0, 0, 0, 0.5); */
    padding: 10px 15px;
    border-radius: 5px;
    color: #fff;
}

.footer-content p,
.footer-content h3 {
    margin: 0;
    font-size: 14px;
}

.form-containers{
    background-color: white;
    padding: 42px;
    /* color: black; */
    color: #000000b8;
    margin: 162px 0;
    height: 510px;
}

.form-group{
    margin: 9px 0;
}

.input{
    
    width: 229px;
}
.button{
    background-color: #d235c3;
    border: none;
    border-radius: 27px;
    width: 104px;
    height: 46px;
    color: white;
}

/* General Mobile Frame */
.mobile-frame {
    width: 375px;
    height: 667px;
    border: 16px solid #000;
    border-radius: 36px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position: relative;
    background-color: #fff;
    overflow: hidden;
}

/* Content Container */
.template-qr-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-family: Arial, sans-serif;
}

/* Header Content */
.header-content {
    position: absolute;
    top: 20px;
    left: 10px;
    right: 10px;
    text-align: center;
    z-index: 10;
    padding: 5px 10px;
    border-radius: 5px;
    /* background-color: rgba(0, 0, 0, 0.5);  */
}

.header-content h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

/* Description Content */
.description {
    position: relative;
    /* text-align: center; */
    margin: auto;
    /* padding: 124px 15px; */
    font-size: 16px;
    color: white;
    line-height: 1.5;
}

/* Footer Content */
.footer-content {
    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    /* background-color: rgba(0, 0, 0, 0.5);  */
}

/* Social Media Button */
.footer-content .socialMedia button {
    background-color: #d235c3;
    border: none;
    border-radius: 27px;
    padding: 10px 20px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;
}

.input{
    width: 228px;
}

.tempalte-form {
    background-color: white !important;
    padding: 51px !important;
    margin-top: -55px !important;
    /* border-radius: 10px !important; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    /* width: 90%; */
    /* max-width: 350px; */
    text-align: left;
    font-family: Arial, sans-serif;
}

button[type="submit"] {
    width: 100%;
    padding: 6px;
    background-color: #d235c3;
    color: white;
    border: none;
    border-radius: 41px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 33px;
}

button[type="submit"]:hover {
    background-color: #b81da5;
}


.form-container {
    position: sticky;
}
.form-group input {
    /* width: 293px; */
    /* border-radius: 4px; */
    height: 29px;
    /* border: 1px solid #000; */
    border: 1px solid #c5c2c2;
    border-radius: 9px;
    height: 37px;
}

.link-container{
    background-color: white;
    padding: 115px;
    border-radius: 8px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
    width: 100%;

}

.rec-img{
    margin: 0 31px;

}

.company-logo{
    width: 102px;
}
.doc-content{
    /* text-align: center; */
    /* text-align: center; */
    background: #ffffff;
    color: black;
    width: 120%;
    padding: 34px;
}

/* .link{
    background: #e0e0e0;
    width: 202px;
    text-align: center;
    border-radius: 13px;
    height: 53px;
    padding: 10px;
}

.link-div{
    margin: 0 -44px;
} */

@media (max-width: 1280px){
    .template-item img {
        height: auto;
        max-height: 200px !important;
    }
}

@media (max-width: 1024px){
    .template-item img {
        height: auto;
        max-height: 154px !important;  
    }
    
}